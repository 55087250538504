import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload'; // Import the download icon
import * as XLSX from 'xlsx'; // Import XLSX for Excel export

const SubmissionList = ({ submissions }) => {
  const navigate = useNavigate();

  // Ensure submissions is an array before mapping over it
  if (!submissions || submissions.length === 0) {
    return <div>No submissions available</div>;
  }

  const handleRowClick = (params) => {
    console.log('Row clicked:', params);
    const { id } = params.row;
    navigate(`/submission/${id}`); // Navigate to the submission details page
  };

  const exportToExcel = () => {
    // Map submissions into a simple array of objects for Excel export
    const dataToExport = submissions.map(submission => ({
      'Sponsorship Name': submission.sponsorshipName,
      'Contact Name': submission.contactName,
      'Contact Email': submission.contactEmail,
      'Contact Phone': submission.contactPhone,
      'Sponsorship Description': submission.sponsorshipDescription,
      'Dollar Amount': submission.dollarAmount,
      'Company Name': submission.companyName,
      'Status': submission.status || 'Pending',
    }));

    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Submissions');

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, 'Submissions_List.xlsx');
  };

  const columns = [
    { field: 'sponsorshipName', headerName: 'Sponsorship Name', flex: 1 },
    { field: 'contactName', headerName: 'Contact Name', flex: 1 },
    { field: 'contactEmail', headerName: 'Contact Email', flex: 1 },
    { field: 'contactPhone', headerName: 'Contact Phone', flex: 1 },
    { field: 'sponsorshipDescription', headerName: 'Sponsorship Description', flex: 2 },
    {
      field: 'dollarAmount',
      headerName: 'Dollar Amount',
      flex: 1,
      renderCell: (params) => {
        // Ensure dollarAmount is treated as a string and prepend the $ symbol
        const value = params.value ? `$${params.value}` : '$0';
        return value;
      },
      filterOperators: [
        {
          label: 'Greater than',
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              const amount = parseFloat(params.value || 0);
              const filterValue = parseFloat(filterItem.value || 0);
              return amount > filterValue;
            };
          },
        },
        {
          label: 'Less than',
          value: 'lessThan',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              const amount = parseFloat(params.value || 0);
              const filterValue = parseFloat(filterItem.value || 0);
              return amount < filterValue;
            };
          },
        },
      ],
    },
    { field: 'companyName', headerName: 'Company Name', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      type: 'singleSelect',
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <IconButton onClick={exportToExcel} color="primary" aria-label="export to excel" style={{ marginBottom: '10px' }}>
        <FileDownloadIcon /> {/* Icon for export */}
      </IconButton>
      <DataGrid
        rows={submissions.map((submission) => ({
          id: `${submission.partitionKey}-${submission.rowKey}`,
          sponsorshipName: submission.sponsorshipName,
          contactName: submission.contactName,
          contactEmail: submission.contactEmail,
          contactPhone: submission.contactPhone,
          sponsorshipDescription: submission.sponsorshipDescription,
          dollarAmount: submission.dollarAmount,
          companyName: submission.companyName,
          status: submission.status || 'Pending',
        }))}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20, 50]}
        onRowClick={handleRowClick} // Trigger navigation on row click
        disableSelectionOnClick // Ensure clicks don't trigger selection but navigation
        initialState={{
          filter: {
            filterModel: {
              items: [{
                field: 'status',
                operator: 'not',
                value: 'Rejected'
              }]
            }
          }
        }}
      />
    </div>
  );
};

export default SubmissionList;